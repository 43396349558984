section {
  margin: 20px 0;
}

article {
  border: 1px solid var(--border);
  padding: 20px;
  margin: 10px 0;
  border-radius: 0.4em;
}

article > :first-child {
  margin-top: 0;
}
article > :first-child > :first-child {
  margin-top: 0;
}
article > :last-child {
  margin-bottom: 0;
}
article > :last-child > :last-child {
  margin-bottom: 0;
}
