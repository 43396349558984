@media print {
  body,
  pre,
  code,
  summary,
  details,
  button,
  input,
  textarea {
    background-color: #fff;
  }

  button,
  input,
  textarea {
    border: 1px solid #000;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  button,
  input,
  textarea,
  footer,
  summary,
  strong {
    color: #000;
  }

  summary::marker {
    color: #000;
  }

  summary::-webkit-details-marker {
    color: #000;
  }

  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  a {
    color: #00f;
    text-decoration: underline;
  }
}
