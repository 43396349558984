button,
select,
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="checkbox"],
input[type="range"],
input[type="radio"] {
  cursor: pointer;
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  display: block;
}

input,
textarea,
select {
  color: var(--text-bright);
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 10px;
  border: none;
  border-radius: 6px;
  outline: none;
  border: 1px solid var(--border-muted);
}

input[type="color"] {
  min-height: 2rem;
  padding: 8px;
  cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
  height: 1.2em;
  width: 1em;
}

input[type="radio"] {
  border-radius: 100%;
}

input {
  vertical-align: top;
}

label {
  vertical-align: middle;
  margin-bottom: 4px;
  display: inline-block;
}

input:not([type="checkbox"]):not([type="radio"]),
input[type="range"],
select,
button,
textarea {
  -webkit-appearance: none;
}

textarea {
  display: block;
  margin-right: 0;
  resize: vertical;
  width: 100%;
}

textarea:not([cols]) {
  width: 100%;
}

textarea:not([rows]) {
  min-height: 40px;
  height: 140px;
}

select {
  background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px
    no-repeat;
  padding-right: 35px;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  padding-right: 10px;
  background-image: none;
  overflow-y: auto;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--focus);
  box-shadow: 0 0 0 3px var(--focus-background);
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

::placeholder {
  color: var(--form-placeholder);
}

fieldset {
  border: 1px var(--border) solid;
  border-radius: 6px;
  margin: 0;
  margin-bottom: 12px;
  padding: 10px 20px;
}

legend {
  font-size: 0.9em;
  font-weight: 600;
}
