html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--sans-serif);
  line-height: 1.7;
  max-width: 50rem;
  margin: 6rem auto;
  padding: 0 10px;
  word-wrap: break-word;
  color: var(--text-main);
  background: var(--background-body);
  text-rendering: optimizeLegibility;
}
