input[type="range"] {
  margin: 10px 0;
  padding: 10px 0;
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 9.5px;
  transition: 0.2s;
  background: var(--background);
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #000, 0 0 1px #0d0d0d;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--border);
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--background);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 9.5px;
  transition: 0.2s;
  background: var(--background);
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--border);
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 9.5px;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: var(--background);
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
  background: var(--background);
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  border: 1px solid #000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--border);
}

input[type="range"]:focus::-ms-fill-lower {
  background: var(--background);
}

input[type="range"]:focus::-ms-fill-upper {
  background: var(--background);
}
